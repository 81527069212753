module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"small":"(max-width: 575px)","medium":"(max-width: 767px)","large":"(max-width: 1023px)","extraLarge":"(max-width: 1329px)","smallUp":"(min-width: 576px)","mediumUp":"(min-width: 768px)","largeUp":"(min-width: 1024px)","extraLargeUp":"(min-width: 1330px)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pédagogie PNL - Apprendre à apprendre avec la PNL","short_name":"Pédagogie PNL","start_url":"/","background_color":"#ffffff","theme_color":"#ed008c","display":"standalone","lang":"fr","icon":"src/assets/icon.png","icon_options":{"purpose":"any maskable"},"cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"globPatterns":["**/*"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-Y12Z71TZDM"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
